import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useWizard } from 'react-use-wizard';
import { compose } from 'recompose';

import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import IconLeft from '@oup/shared-front-end/src/svg/oup/icon-left-24.svg';

import { HubLayoutConstants } from '../../../globals/hubConstants';
import { getSelfSelectedRole } from '../../../globals/userRoles';
import wizardStyles from '../SelfRegisteredUserOnboardingWizard.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingWizardConfirmation({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent },
  setIsOpenWhatToDoNextModal,
  setIsEnterACodeModalOpen
}) {
  const { previousStep } = useWizard();
  const [selfSelectedRole, setSelfSelectedRole] = useState('');
  const { SELF_SELECT_ROLES } = HubLayoutConstants;

  useEffect(() => {
    setSelfSelectedRole(getSelfSelectedRole());
  }, []);

  const isStudentRole = selfSelectedRole === SELF_SELECT_ROLES[0].key;

  const handleDone = () => {
    if (isStudentRole) {
      setIsEnterACodeModalOpen(true);
    } else setIsOpenWhatToDoNextModal(true);
  };

  return (
    <>
      <div data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CONFIRMATION_CONTAINER" className={wizardStyles.step}>
        <div className={wizardStyles.stepContent}>
          <h1>
            {isStudentRole
              ? content.self_registered_user_onboarding_wizard_student_role_chosen_title
              : content.self_registered_user_onboarding_wizard_staff_role_chosen_title}
          </h1>
          <h2>
            {isStudentRole
              ? content.self_registered_user_onboarding_wizard_student_role_chosen_description
              : content.self_registered_user_onboarding_wizard_staff_role_chosen_description}
          </h2>
        </div>
        <div className={wizardStyles.imageContainer}>
          <img src="/media/feedback/illustration-success.svg" alt="Welcome to Oxford English Hub" />
        </div>
      </div>
      <footer className={classnames(wizardStyles.footer, wizardStyles.multipleButtonsFooter)}>
        <TextLink
          dataTestId="SELF_REGISTERED_USER_ONBOARDING_WIZARD_BACK_BUTTON"
          leftIcon={<IconLeft />}
          onClick={e => {
            e.preventDefault();
            previousStep();
          }}
        >
          {hubContent.button_back_text}
        </TextLink>
        <Button
          dataAttributes={{ testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON' }}
          variant="filled"
          text={hubContent.done_button}
          disabled={false}
          onClick={handleDone}
        />
      </footer>
    </>
  );
}

SelfRegisteredUserOnboardingWizardConfirmation.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  setIsOpenWhatToDoNextModal: PropTypes.func.isRequired,
  setIsEnterACodeModalOpen: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'))(
  SelfRegisteredUserOnboardingWizardConfirmation
);
