import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import uuidV4 from 'uuid/v4';
import get from 'lodash.get';
// Styles
import styles from './Person.scss';
// Constants
import breakpoints from '../../../globals/breakpoints';
import { HubLayoutConstants, SINGLE_CLASS_PAGES_CONTEXT } from '../../../globals/hubConstants';
import { featureIsEnabled } from '../../../globals/envSettings';
// Services
import getPeopleColour from '../../HubDashboardLayout/Services/getPeopleColour';
import getPeopleStatusIconData from '../../HubDashboardLayout/Services/getPeopleStatusIconData';
import getPeopleInitials from '../../HubDashboardLayout/Services/getPeopleInitials';
import getDropdownItems from '../../../components/HubDropdownMenu/Services/getDropdownItems';
import getClassLink from '../../HubDashboardLayout/Services/getClassLink';
import getClassPeopleDropdownOptions from '../../HubDashboardLayout/Services/getClassPeopleDropdownOptions';
import userRoles, { roleIsAtLeast } from '../../../globals/userRoles';
import getOrganizationClassLink from '../../HubDashboardLayout/Utils/getOrganizationClassLink';
// Components
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import Checkbox from '../../../components/Checkbox/Checkbox';
import HubDropdownMenu from '../../../components/HubDropdownMenu/HubDropdownMenu';
import getUserPanelType from '../../HubDashboardLayout/Services/getUserPaneltype';
import Tooltip from '../../../components/Tooltip/Tooltip';

function Person({
  ids,
  peopleIds,
  items,
  role,
  hubContent,
  classroomId,
  breakpoint,
  checkboxData: { onSelect, selectedIds, checkAllSelected, setAllSelected },
  isTeacherAssignedToClass,
  actions,
  authUser,
  dropdownTop,
  push,
  context,
  showCheckbox = true,
  showDropdown = true,
  currentOrganisationLti = false
}) {
  useEffect(() => {
    const allPeopleSelected = ids.every(entry => selectedIds.includes(entry));
    setAllSelected(allPeopleSelected);
  }, []);
  const {
    ASSIGN_MATERIALS_TO_USERS,
    RESEND_INVITATION,
    RESET_PASSWORD_REQUEST,
    REGENERATE_SUPERVISED_USER_SIGN_IN_CARD
  } = HubLayoutConstants.PEOPLE_PANEL_TYPES;

  const _getNameOfUser = id => `${items[id].firstname} ${items[id].lastname}`;

  return (
    <ul>
      {ids && ids.length
        ? ids.map((id, index) => {
            const item = get(items, [id], {});
            const { firstname = '', lastname = '', username = '', roleName = '', email = '' } = item;
            const simpleUsername = username.slice(0, username.indexOf('@'));
            const initials = getPeopleInitials(firstname, lastname);

            const peopleColour = getPeopleColour(role);
            const { icon, iconColour, date, text, subtext = '', status = '' } = getPeopleStatusIconData(
              item,
              classroomId,
              hubContent
            );

            const tooltipData = `${text} ${date} ${subtext || ''}`;
            const roleType = roleIsAtLeast(userRoles.TEACHER, roleName) ? userRoles.TEACHER : userRoles.LEARNER;
            const isSelfSelectedOrgAdmin = roleType === userRoles.ORG_ADMIN && authUser.id === id;
            const typeOfRemovePanel = getUserPanelType(roleName);
            const hideRemoveOption = authUser.id === id && !roleIsAtLeast(userRoles.TEACHER_ADMIN, authUser.role);
            const dropdownOptions = getClassPeopleDropdownOptions(
              item,
              classroomId,
              hideRemoveOption,
              item.isSupervised,
              roleName === userRoles.LEARNER
            );
            const dropdownActions = {
              removeUser: e => {
                e.stopPropagation();
                actions.removeUser(e, typeOfRemovePanel, { selectedUserId: id });
              },
              sendInvitationReminder: e => {
                e.stopPropagation();
                actions.invitationReminder(e, RESEND_INVITATION, { selectedUserId: id });
              },
              assignMaterial: e => {
                e.stopPropagation();
                return actions.assignMaterial(e, ASSIGN_MATERIALS_TO_USERS, {
                  userType: role,
                  role: roleType,
                  selectedUserId: id,
                  selectedUsers: [id],
                  contextName: _getNameOfUser(id)
                });
              },
              sendPasswordResetEmail: e => {
                e.stopPropagation();
                return actions.sendPasswordResetEmail(e, RESET_PASSWORD_REQUEST, {
                  selectedUserId: id,
                  selectedUsers: [id],
                  selectedUserEmail: email
                });
              },
              regenerateSupervisedUserSignInCard: e => {
                e.stopPropagation();
                return actions.regenerateSupervisedUserSignInCard(e, REGENERATE_SUPERVISED_USER_SIGN_IN_CARD, {
                  selectedUserId: id,
                  selectedUsers: [id],
                  userDetails: { firstName: item.firstname, userName: item.username }
                });
              }
            };

            const { isArchived, isPending, isActive } = dropdownOptions;
            const entryCanHaveCheckbox = (isActive && !isArchived) || (isActive && isPending);
            const shouldShowDropdown =
              breakpoint !== breakpoints.XXS && isTeacherAssignedToClass && entryCanHaveCheckbox && showDropdown;

            const redirectPeopleDetails = () => {
              if (context === SINGLE_CLASS_PAGES_CONTEXT.ORG_CONTEXT) {
                push(`${getOrganizationClassLink(classroomId)}/people/${id}`);
              } else {
                push(`${getClassLink(classroomId)}/people/${id}`);
              }
            };

            return (
              <li
                data-testid={`PEOPLE_ROW_${roleType === 'LEARNER' ? 'STUDENT' : 'TEACHER'}`}
                key={uuidV4()}
                data-id={id}
                onClick={() => {
                  if (featureIsEnabled('hub-include-person-profile-page') && entryCanHaveCheckbox)
                    redirectPeopleDetails();
                }}
                className={`${styles.personContainer} ${
                  selectedIds.includes(id) ? styles.active : ''
                } ${!entryCanHaveCheckbox && styles.personRemoved}`}
                role="none"
                onKeyDown={e => {
                  if (featureIsEnabled('hub-include-person-profile-page') && entryCanHaveCheckbox && e.key === 'Enter')
                    redirectPeopleDetails();
                }}
              >
                <div className={styles.leftContainer}>
                  <div className={styles.checkboxContainer}>
                    {isTeacherAssignedToClass && entryCanHaveCheckbox && showCheckbox ? (
                      <Checkbox
                        id={id}
                        label="Select entity"
                        labelHidden
                        value={selectedIds.includes(id)}
                        onChange={() => {
                          onSelect(id, !selectedIds.includes(id));
                          checkAllSelected(peopleIds);
                        }}
                      />
                    ) : (
                      <div className={styles.placeholder} />
                    )}
                  </div>
                  <div className={styles.userDetailsContainer}>
                    <div className={`${styles.userInfoDot} ${styles[peopleColour]}`}>{initials}</div>
                    <div className={styles.userInfoContainer}>
                      <div>
                        <span>{`${firstname} ${lastname}`}</span>
                      </div>
                      <div>
                        <span>{currentOrganisationLti ? simpleUsername : username}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.middleContainer}>
                  <div className={styles.svgContainer}>
                    <Tooltip title={breakpoint === breakpoints.XXS ? tooltipData : ''} className={styles.statusTooltip}>
                      <SVGIcon glyph={icon} fill={iconColour} />
                    </Tooltip>
                  </div>
                  <div className={styles.textContainer}>
                    <span>{text}</span>
                    <span className={`${styles[status.toLowerCase()] || ''}`}>{date}</span>
                    {subtext ? <span>{subtext}</span> : ''}
                  </div>
                  {breakpoint === breakpoints.XXS && isTeacherAssignedToClass && showDropdown && (
                    <div className={styles.dropdownContainer}>
                      <HubDropdownMenu
                        dropdownData={getDropdownItems(
                          'people',
                          hubContent,
                          dropdownOptions,
                          dropdownActions,
                          roleType,
                          isSelfSelectedOrgAdmin,
                          currentOrganisationLti
                        )}
                        parentId={id}
                        dropdownTop={dropdownTop}
                        customClassname={styles.dropDownMenu}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.rightContainer}>
                  {shouldShowDropdown && (
                    <HubDropdownMenu
                      dropdownData={getDropdownItems(
                        'people',
                        hubContent,
                        dropdownOptions,
                        dropdownActions,
                        roleType,
                        isSelfSelectedOrgAdmin,
                        currentOrganisationLti
                      )}
                      parentId={id}
                      dropdownTop={
                        /** If total results over 1, last 2 items dropdown render on top */
                        dropdownTop || (ids.length >= 2 && (index === ids.length - 1 || index === ids.length - 2))
                      }
                      customClassname={styles.dropDownMenu}
                    />
                  )}
                </div>
              </li>
            );
          })
        : null}
    </ul>
  );
}

Person.propTypes = {
  ids: PropTypes.array,
  peopleIds: PropTypes.array.isRequired,
  isTeacherAssignedToClass: PropTypes.bool.isRequired,
  items: PropTypes.object,
  role: PropTypes.string,
  hubContent: PropTypes.object.isRequired,
  checkboxData: PropTypes.object.isRequired,
  classroomId: PropTypes.string,
  breakpoint: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  dropdownTop: PropTypes.bool,
  push: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  showCheckbox: PropTypes.bool,
  showDropdown: PropTypes.bool,
  currentOrganisationLti: PropTypes.bool
};

export default Person;
