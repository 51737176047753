import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useWizard } from 'react-use-wizard';
import Button from '@oup/shared-front-end/src/components/Button';
import wizardStyles from '../SelfRegisteredUserOnboardingWizard.scss';
import roleSelectStyles from './SelfRegisteredUserOnboardingWizardChooseRole.scss';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import RoleTile from '../../RoleTile/RoleTile';
import withLocalizedContent from '../../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingWizardChooseRole({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent },
  selectRole
}) {
  const { nextStep } = useWizard();
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [selectedRole, setSelectedRole] = useState(null);

  const handleRoleSelect = roleKey => {
    setSelectedRole(roleKey);
    selectRole(roleKey, true);
    setIsNextDisabled(false);
  };
  return (
    <>
      <div
        data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CHOOSE_ROLE_CONTAINER"
        className={roleSelectStyles.chooseRoleStep}
      >
        <div className={roleSelectStyles.stepContent}>
          <h1>{content.self_registered_user_onboarding_wizard_choose_role}</h1>
          <h2>{content.self_registered_user_onboarding_wizard_role_choose_step_subtitle}</h2>
        </div>
        <div className={roleSelectStyles.roleSelectContainer}>
          <ul>
            {HubLayoutConstants.SELF_SELECT_ROLES.map(item => (
              <RoleTile
                key={item.title}
                item={item}
                selectRole={() => {
                  handleRoleSelect(item.key);
                }}
                isSelected={selectedRole === item.key}
                wizardMode
              />
            ))}
          </ul>
        </div>
      </div>
      <footer className={wizardStyles.footer}>
        <Button
          dataAttributes={{ testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON' }}
          variant="filled"
          text={hubContent.next}
          disabled={isNextDisabled}
          onClick={() => nextStep()}
        />
      </footer>
    </>
  );
}

SelfRegisteredUserOnboardingWizardChooseRole.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  selectRole: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'))(
  SelfRegisteredUserOnboardingWizardChooseRole
);
