import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

// Reducers
import { closeMaterialsToClassModal } from '../../../redux/actions/hubUi';
// Constants
import { HubLayoutConstants } from '../../../globals/hubConstants';
import { assignmentContexts } from '../../../globals/assignmentConstants';
// Components
import PopoutPanel from '../../../components/PopoutPanel/PopoutPanel';
import AssignLearningMaterial from '../../../routes/panels/AssignLearningMaterial/AssignLearningMaterial';
import ResendInvitation from '../../../routes/panels/ResendInvitation/ResendInvitation';
import RemoveStudentsPanel from '../../../routes/ClassroomPage/tabs/StudentsTab/panels/RemoveStudents/RemoveStudents';
import RemoveTeachersPanel from '../../../routes/ClassroomPage/tabs/TeachersTab/panels/RemoveTeachers/RemoveTeachers';
import RemoveMultipleUsers from './RemoveMultipleUsers';
import RemoveLearningMaterial from '../../../routes/panels/RemoveLearningMaterial/RemoveLearningMaterial';
import { featureIsEnabled } from '../../../globals/envSettings';
import { isHubMode } from '../../../utils/platform';
import ProductFinderPanel from '../../../components/ProductFinder/ProductFinderPanel';
import OrgUserEditPanel from '../../../panels/OrgUserEditPanel';
import RegenerateSupervisedUserSignInCardPanel from '../../../panels/RegenerateSupervisedUserSignInCardPanel';
import ManageProductLocks from '../../../components/ManageProductLocks/ManageProductLocks';

function PanelContainer({
  panelType,
  panelData = {},
  closePopupPanel,
  orgId,
  classId,
  canManageAssignments = false,
  hubContent,
  actions: { handleOnCompleteRemoveMaterialsFromClass = null },
  selectedUsers,
  contextName,
  materialsToClassModalOpen,
  materialsToClassModalContent,
  closeMaterialsToClassModalAction
}) {
  const {
    PEOPLE_PANEL_TYPES: {
      REMOVE_STUDENTS,
      REMOVE_TEACHERS,
      REMOVE_MULTIPLE_USERS,
      ASSIGN_MATERIALS_TO_USERS,
      RESEND_INVITATION,
      RESET_PASSWORD_REQUEST,
      REGENERATE_SUPERVISED_USER_SIGN_IN_CARD
    },
    CLASSWORK_PANEL_TYPES: { REMOVE_MATERIALS_FROM_CLASS, LOCK_PRODUCT_CONTENT }
  } = HubLayoutConstants;

  const { CLASS_USERS } = assignmentContexts;

  return (
    <div>
      <RemoveStudentsPanel
        isOpen={panelType === REMOVE_STUDENTS}
        closePanel={closePopupPanel}
        onComplete={closePopupPanel}
      />
      <RemoveTeachersPanel
        isOpen={panelType === REMOVE_TEACHERS}
        closePanel={closePopupPanel}
        onComplete={closePopupPanel}
      />
      <RemoveMultipleUsers
        isOpen={panelType === REMOVE_MULTIPLE_USERS}
        closePanel={closePopupPanel}
        onComplete={closePopupPanel}
      />
      <PopoutPanel id="resendInvitation" isOpen={panelType === RESEND_INVITATION} ariaLabel="Resend Invitation">
        <ResendInvitation orgId={orgId} closePopoutAction={closePopupPanel} onComplete={closePopupPanel} />
      </PopoutPanel>
      {canManageAssignments && (
        <PopoutPanel
          ariaLabel={hubContent.assign_material_to_class_panel_aria}
          id="assignPopout"
          isOpen={panelType === ASSIGN_MATERIALS_TO_USERS || materialsToClassModalOpen}
        >
          {featureIsEnabled('product-finder-refactor') && isHubMode() ? (
            <ProductFinderPanel
              orgId={orgId}
              selectedUsers={selectedUsers}
              contextName={materialsToClassModalOpen ? contextName : panelData.contextName}
              classId={materialsToClassModalOpen ? classId : panelData.classId}
              onClose={() => {
                closePopupPanel();
                closeMaterialsToClassModalAction();
              }}
              onComplete={() => {
                closePopupPanel();
                closeMaterialsToClassModalAction();
              }}
              // needed for polling. will be removed when polling is removed
              context={materialsToClassModalOpen ? materialsToClassModalContent : CLASS_USERS}
            />
          ) : (
            <AssignLearningMaterial
              context={materialsToClassModalOpen ? materialsToClassModalContent : CLASS_USERS}
              orgId={orgId}
              closePopoutAction={() => {
                closePopupPanel();
                closeMaterialsToClassModalAction();
              }}
              onComplete={() => {
                closePopupPanel();
                closeMaterialsToClassModalAction();
              }}
            />
          )}
        </PopoutPanel>
      )}
      <PopoutPanel
        id="removeLearningMaterialPopout"
        ariaLabel={hubContent.remove_material_from_class_panel_aria}
        isOpen={panelType === REMOVE_MATERIALS_FROM_CLASS}
      >
        <RemoveLearningMaterial
          onComplete={() => {
            closePopupPanel();
            setTimeout(handleOnCompleteRemoveMaterialsFromClass, 300);
          }}
          closePanel={closePopupPanel}
        />
      </PopoutPanel>

      <OrgUserEditPanel
        orgId={orgId}
        userId={panelData.selectedUserId}
        isOpen={panelType === RESET_PASSWORD_REQUEST}
        closePanel={closePopupPanel}
      />

      <RegenerateSupervisedUserSignInCardPanel
        isOpen={panelType === REGENERATE_SUPERVISED_USER_SIGN_IN_CARD}
        onClose={closePopupPanel}
      />

      <ManageProductLocks
        isOpen={panelType === LOCK_PRODUCT_CONTENT}
        content={hubContent}
        classId={panelData.classId}
        productDetails={panelData.productDetails}
        onClosePanel={closePopupPanel}
      />
    </div>
  );
}

PanelContainer.propTypes = {
  panelType: PropTypes.string.isRequired,
  panelData: PropTypes.object,
  closePopupPanel: PropTypes.func.isRequired,
  orgId: PropTypes.string,
  classId: PropTypes.string,
  canManageAssignments: PropTypes.bool,
  hubContent: PropTypes.object,
  actions: PropTypes.object,
  selectedUsers: PropTypes.object,
  contextName: PropTypes.string,
  materialsToClassModalOpen: PropTypes.bool,
  materialsToClassModalContent: PropTypes.string,
  closeMaterialsToClassModalAction: PropTypes.func
};

export default compose(connect(() => ({}), { closeMaterialsToClassModalAction: closeMaterialsToClassModal }))(
  PanelContainer
);
