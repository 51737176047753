import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
// Components
import TileContainer from '../../components/TileContainer/TileContainer';
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';
import { loadCourses } from '../../redux/actions/hubCourses';
import DataRefresher from '../../components/DataRefresher/DataRefresher';
import MyDashboardButton from './MyDashboardButton';
import { isLtiMode } from '../../utils/platform';
import { featureIsEnabled } from '../../globals/envSettings';
import { setClassroomsToArchive } from '../../redux/reducers/classroomArchive';

const views = { CLASSES: 'myClasses', COURSES: 'myCourses' };
function MyDashboardContent({
  windowWidth,
  dashboardButtonData,
  tileContainerData,
  hubContent,
  breakpoint,
  sideNavOpen,
  history,
  userId,
  userRole,
  currentOrganisationId,
  myDashboardView,
  initialiseSearch,
  loadCourseData,
  customOrgId,
  currentOrganisationLti,
  setClassToArchiveAction
}) {
  useEffect(() => () => setClassToArchiveAction([], currentOrganisationId), []);

  return (
    <div>
      {tileContainerData.showDashboardButton && !isLtiMode() && <MyDashboardButton data={dashboardButtonData} />}
      {!featureIsEnabled('opt-main-features') && (
        <DataRefresher
          refreshData={() => {
            switch (myDashboardView) {
              case views.CLASSES:
                return initialiseSearch('profileClasses', false, {
                  active: true,
                  orgId: currentOrganisationId,
                  userId
                });
              case views.COURSES:
                return loadCourseData();
              default:
                return () => {};
            }
          }}
        />
      )}
      <TileContainer
        data={tileContainerData}
        hubContent={hubContent}
        breakpoint={breakpoint}
        windowWidth={windowWidth}
        sideNavOpen={sideNavOpen}
        isLimited
        history={history}
        userId={userId}
        userRole={userRole}
        currentOrganisationId={currentOrganisationId}
        currentOrganisationLti={currentOrganisationLti}
        myDashboardView={myDashboardView}
        customOrgId={customOrgId}
      />
    </div>
  );
}

MyDashboardContent.propTypes = {
  tileContainerData: PropTypes.object.isRequired,
  dashboardButtonData: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  hubContent: PropTypes.object.isRequired,
  sideNavOpen: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired,
  history: PropTypes.object,
  userId: PropTypes.string,
  userRole: PropTypes.string,
  currentOrganisationId: PropTypes.string,
  myDashboardView: PropTypes.string.isRequired,
  initialiseSearch: PropTypes.func,
  loadCourseData: PropTypes.func,
  customOrgId: PropTypes.string,
  currentOrganisationLti: PropTypes.bool,
  setClassToArchiveAction: PropTypes.func.isRequired
};

export default compose(
  connect(({ identity: { currentOrganisationLti } }) => ({ currentOrganisationLti }), {
    loadCourseData: loadCourses,
    initialiseSearch: initialiseInstance,
    setClassToArchiveAction: setClassroomsToArchive
  })
)(MyDashboardContent);
