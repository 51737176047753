import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useWizard } from 'react-use-wizard';
import Button from '@oup/shared-front-end/src/components/Button';
import wizardStyles from '../SelfRegisteredUserOnboardingWizard.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingWizardWelcome({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent },
  firstName,
  selfSelectRoleModalOpen
}) {
  const { nextStep, goToStep } = useWizard();

  useEffect(() => {
    if (selfSelectRoleModalOpen) goToStep(2);
  }, []);

  return (
    <>
      <div data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_WELCOME_CONTAINER" className={wizardStyles.step}>
        <div className={wizardStyles.stepContent}>
          <h1>{`${hubContent.self_select_role_title.replace('{firstName}', firstName)}`}</h1>
          <h2>{content.self_registered_user_onboarding_wizard_set_up}</h2>
        </div>
        <div className={wizardStyles.imageContainer}>
          <img src="/media/feedback/illustration-welcome.svg" alt="Welcome to Oxford English Hub" />
        </div>
      </div>
      <footer className={wizardStyles.footer}>
        <Button
          dataAttributes={{ testid: 'SELF_REGISTERED_USER_ONBOARDING_WIZARD_NEXT_BUTTON' }}
          variant="filled"
          text={hubContent.next}
          disabled={false}
          onClick={() => nextStep()}
        />
      </footer>
    </>
  );
}

SelfRegisteredUserOnboardingWizardWelcome.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  selfSelectRoleModalOpen: PropTypes.bool
};

export default compose(withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'))(
  SelfRegisteredUserOnboardingWizardWelcome
);
