import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import has from 'lodash.has';

// Feature flag content to be removed in the future
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { userRoles } from '@oup/shared-node-browser/user';
import TileContainer from '../../components/TileContainer/TileContainer';
import { featureIsEnabled } from '../../globals/envSettings';
import MyDashboardButton from './MyDashboardButton';
// Feature flag content to be removed in the future
// Styles
import styles from './MyDashboard.scss';
// Constants
import { getDashboardTabs, HubLayoutConstants, HubIllustrationConstants } from '../../globals/hubConstants';
// Services
import getClassLink from '../../structure/HubDashboardLayout/Services/getClassLink';
import processUserRole from '../../structure/HubDashboardLayout/Services/processUserRole';
import getCourseLink from '../../structure/HubDashboardLayout/Services/getCourseLink';
import { loadCourses } from '../../redux/actions/hubCourses';
// Components
import HubTabsNavigation from '../../components/HubTabsNavigation/HubTabsNavigation';
import CourseTile from '../../components/CourseTile/CourseTile';
import DataRefresher from '../../components/DataRefresher/DataRefresher';
import ClassTile from '../../components/ClassTile/ClassTile';
import { isLtiMode } from '../../utils/platform';

// Redux
import {
  openRedeemModal,
  openCreateClassModal,
  openJoinClassWithCodeModal,
  toggleArchiveClassModal,
  selectMyDashboardView,
  openOnboardingWizard,
  openSelfRegisteredWizard,
  showSelfSelectRoleModal
} from '../../redux/actions/hubUi';
// HOC
import withLocalizedContent from '../../language/withLocalizedContent';
import withBreakpoint from '../../decorators/withBreakpoint';
import { setClassroomsToArchive } from '../../redux/reducers/classroomArchive';
import HubMyDashboardBanner from '../../components/HubMyDashboardBanner/HubMyDashboardBanner';

class MyDashboard extends Component {
  componentDidMount() {
    const {
      props: { myDashboardView }
    } = this;

    this.selectView(myDashboardView);
  }

  getDashboardButtonData = () => {
    const {
      // eslint-disable-next-line no-shadow
      props: {
        areClassesLoading,
        areCoursesLoading,
        classesIds,
        courseIds,
        hubContent,
        userRole,
        // eslint-disable-next-line no-shadow
        openRedeemModal,
        // eslint-disable-next-line no-shadow
        openJoinClassWithCodeModal,
        // eslint-disable-next-line no-shadow
        openOnboardingWizard
      }
    } = this;

    const isSelfLearner = () => userRole === userRoles.USER || userRole === userRoles.LEARNER;

    return {
      myClasses: isSelfLearner()
        ? {
            isLoading: areClassesLoading,
            ids: classesIds,
            onClick: openJoinClassWithCodeModal,
            text: hubContent.join_class_with_code,
            ariaLabel: hubContent.join_class_with_code_screen_reader
          }
        : {
            isLoading: areClassesLoading,
            ids: classesIds,
            onClick: openOnboardingWizard,
            text: hubContent.create_class,
            ariaLabel: hubContent.opens_in_a_panel
          },
      myCourses: {
        isLoading: areCoursesLoading,
        ids: courseIds,
        onClick: openRedeemModal,
        text: hubContent.enter_a_code_text,
        ariaLabel: hubContent.opens_in_a_panel
      }
    };
  };

  getDashboardViewData = (processedUserRole = {}) => {
    const {
      props: {
        areClassesLoading,
        areCoursesLoading,
        courseIds,
        hubContent,
        classes,
        courses,
        userRole,
        // eslint-disable-next-line no-shadow
        openRedeemModal,
        // eslint-disable-next-line no-shadow
        openJoinClassWithCodeModal,
        // eslint-disable-next-line no-shadow
        openOnboardingWizard,
        showSelfSelectRoleModalAction,
        openSelfRegisteredWizardAction
      }
    } = this;

    const {
      CLASSES: {
        emptyStateTitle = '',
        emptyStateSubtitle = '',
        showFirstEmptyStateButton = false,
        firstButtonText = '',
        firstButtonLink = '',
        firstButtonIcon = '',
        onClickFirstButton = openOnboardingWizard,
        isFirstButtonPrimary = false,
        showSecondEmptyStateButton = false,
        secondButtonText = '',
        secondButtonLink = '',
        secondButtonTarget = '_self',
        isSecondButtonPrimary = false,
        showEmptyStateJoinClassWithCodeButton = false,
        joinClassWithCodeButtonText = '',
        onClickJoinClassWithCodeButton = openJoinClassWithCodeModal,
        hasPlaceholder = !isLtiMode() &&
          !!(userRole === userRoles.TEACHER || userRole === userRoles.TEACHER_ADMIN || userRole === userRoles.ORG_ADMIN)
      } = {},
      COMMON: { showCreateClassButton = false, showJoinClassWithCodeCommonButton = false } = {}
    } = processedUserRole;

    const classesIds = Object.keys(classes);

    return {
      myClasses: {
        isLoading: areClassesLoading,
        ids: classesIds,
        iconSrc: HubIllustrationConstants.CLASS_STUDENTS,
        title: emptyStateTitle,
        subtitle: emptyStateSubtitle,
        showDashboardButton:
          userRole === userRoles.USER || userRole === userRoles.LEARNER
            ? showJoinClassWithCodeCommonButton
            : showCreateClassButton,
        showFirstEmptyStateButton,
        firstButtonText,
        firstButtonLink,
        firstButtonIcon,
        onClickFirstButton,
        isFirstButtonPrimary,
        showSecondEmptyStateButton,
        secondButtonText,
        secondButtonLink,
        secondButtonTarget,
        isSecondButtonPrimary,
        showEmptyStateJoinClassWithCodeButton,
        joinClassWithCodeButtonText,
        onClickJoinClassWithCodeButton,
        items: classes,
        getLink: getClassLink,
        limitCardLink: HubLayoutConstants.PATH_NAMES.CLASSES_PATH,
        limitCardText: hubContent.show_all_classes,
        Tile: ClassTile,
        hasPlaceholder,
        setClassToArchive: this._setClassToArchive,
        firstButtonTestHook: 'OPEN_ONBOARDING_WIZARD_BUTTON_CREATE_A_CLASS_TILE',
        firstButtonTestHookEmptyState: 'OPEN_ONBOARDING_WIZARD_BUTTON_DASHBOARD'
      },
      myCourses: {
        isLoading: areCoursesLoading,
        ids: courseIds,
        iconSrc: HubIllustrationConstants.DIGITAL_LIBRARY,
        title: hubContent.no_courses_text,
        subtitle: hubContent.no_courses_access_code_text,
        showDashboardButton: true,
        showFirstEmptyStateButton: true,
        firstButtonText: hubContent.enter_a_code_text,
        firstButtonIcon,
        onClickFirstButton: openRedeemModal,
        isFirstButtonPrimary: true,
        getLink: getCourseLink,
        items: courses,
        limitCardLink: HubLayoutConstants.PATH_NAMES.COURSES_PATH,
        limitCardText: hubContent.show_all_courses,
        Tile: CourseTile
      },
      emptyDashboard: {
        iconSrc: HubIllustrationConstants.DIGITAL_LIBRARY,
        title: hubContent.empty_dashboard_title,
        ids: [],
        subtitle: hubContent.empty_dashboard_subtitle,
        showDashboardButton: true,
        showFirstEmptyStateButton: true,
        showSecondEmptyStateButton: true,
        position: 'bottom',
        firstButtonText: hubContent.empty_dashboard_first_button_text,
        secondButtonText: hubContent.empty_dashboard_support_link_text,
        secondButtonLink: hubContent.empty_dashboard_support_link_url,
        onClickFirstButton: () =>
          featureIsEnabled('opt-main-features')
            ? openSelfRegisteredWizardAction(true)
            : showSelfSelectRoleModalAction(true),
        isFirstButtonPrimary: true,
        isSecondButtonPrimary: false
      }
    };
  };

  _setClassToArchive = classId => {
    const { currentOrganisationId, archiveClassModalAction, setClassToArchiveAction } = this.props;
    if (classId && currentOrganisationId) setClassToArchiveAction(classId, currentOrganisationId);
    archiveClassModalAction();
  };

  selectView = view => {
    const {
      // eslint-disable-next-line no-shadow
      props: { selectMyDashboardView }
    } = this;

    selectMyDashboardView(view);
  };

  render() {
    const {
      props: {
        classes,
        hubContent,
        breakpoint,
        courseIds,
        firstName,
        myDashboardView,
        windowWidth,
        sideNavOpen,
        areCoursesLoading,
        history,
        userId,
        userRole,
        currentOrganisationId,
        orgName,
        loadCoursesData,
        organization,
        areClassesLoading,
        userPlatformStatistics
      },
      selectView,
      getDashboardButtonData,
      getDashboardViewData
    } = this;

    const classesIds = Object.keys(classes);

    const tabs = getDashboardTabs(
      hubContent,
      classesIds.length,
      courseIds.length,
      areClassesLoading,
      areCoursesLoading
    );

    const userHasLicences =
      has(userPlatformStatistics, 'error') &&
      !userPlatformStatistics.error &&
      Object.keys(userPlatformStatistics.redeemed).length > 0;

    const processedUserRole = processUserRole(
      userRole,
      hubContent,
      featureIsEnabled('opt-main-features') && userHasLicences
    );
    const { CLASSES: { showMyDashboardTabs = false } = {} } = processedUserRole;

    const showMyCoursesTitle = !areCoursesLoading && !!courseIds.length;
    // Add classes feature flag condition on displaying tabs

    return (
      <div className={styles.dashboard}>
        <Helmet title={hubContent.my_dashboard_page_title} titleTemplate={hubContent.page_title_template} />
        <div className={styles.dashboardMain}>
          <HubMyDashboardBanner firstName={firstName} userRole={userRole} orgName={orgName} hubContent={hubContent} />
          {showMyDashboardTabs ? (
            <div>
              <HubTabsNavigation>
                <div>
                  <HubTabsNavigation.HubTabs tabs={tabs} selectView={selectView} view={myDashboardView} />
                </div>
                <div>
                  <HubTabsNavigation.MyDashboardContent
                    dashboardButtonData={getDashboardButtonData()[myDashboardView]}
                    tileContainerData={getDashboardViewData(processedUserRole)[myDashboardView]}
                    hubContent={hubContent}
                    breakpoint={breakpoint}
                    windowWidth={windowWidth}
                    sideNavOpen={sideNavOpen}
                    history={history}
                    userId={userId}
                    userRole={userRole}
                    currentOrganisationId={currentOrganisationId}
                    myDashboardView={myDashboardView}
                    customOrgId={organization?.customId}
                  />
                </div>
              </HubTabsNavigation>
            </div>
          ) : (
            // No tabs scenario (courses Only)
            <div>
              {showMyCoursesTitle && (
                <div className={styles.dashboardFeatureFlagContent}>
                  <h2>{hubContent.my_courses}</h2>
                  <MyDashboardButton data={getDashboardButtonData().myCourses} />
                </div>
              )}
              {featureIsEnabled('opt-main-features') ? (
                <TileContainer
                  data={getDashboardViewData().emptyDashboard}
                  hubContent={hubContent}
                  breakpoint={breakpoint}
                  windowWidth={windowWidth}
                  sideNavOpen={sideNavOpen}
                  isLimited
                  history={history}
                />
              ) : (
                <>
                  <DataRefresher loading={areCoursesLoading} refreshData={() => loadCoursesData()} />
                  <TileContainer
                    data={getDashboardViewData().myCourses}
                    hubContent={hubContent}
                    breakpoint={breakpoint}
                    windowWidth={windowWidth}
                    sideNavOpen={sideNavOpen}
                    isLimited
                    history={history}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    ({
      hubCourses: { courses = {}, loading = true, courseIds = [] } = {},
      search: { profileClasses: { currentClassList = {}, ids = [] } = {} } = {},
      identity: { userId, role: userRole, currentOrganisationId },
      hubUi: { sideNavOpen, myDashboardView },
      people: { data },
      organisations: { data: orgData },
      userPlatformStatistics
    }) => {
      // Display only active classes
      const activeClasses = Object.keys(currentClassList).reduce((res, classId) => {
        if (!currentClassList[classId].archived) {
          res[classId] = currentClassList[classId];
        }
        return res;
      }, {});
      return {
        organization: orgData[currentOrganisationId] || {},
        courses,
        courseIds,
        areCoursesLoading: loading,
        classes: activeClasses,
        classesIds: ids,
        sideNavOpen,
        userId,
        userRole,
        currentOrganisationId,
        orgName: get(orgData, [currentOrganisationId, 'name'], ''),
        firstName: get(data, [userId, 'firstname']),
        myDashboardView,
        userPlatformStatistics
      };
    },
    {
      openRedeemModal,
      openCreateClassModal,
      openJoinClassWithCodeModal,
      selectMyDashboardView,
      loadCoursesData: loadCourses,
      setClassToArchiveAction: setClassroomsToArchive,
      archiveClassModalAction: toggleArchiveClassModal,
      openOnboardingWizard,
      showSelfSelectRoleModalAction: showSelfSelectRoleModal,
      openSelfRegisteredWizardAction: openSelfRegisteredWizard
    }
  ),
  withLocalizedContent('hubGlossary'),
  withBreakpoint
)(MyDashboard);

MyDashboard.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  courses: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  classesIds: PropTypes.array.isRequired,
  openRedeemModal: PropTypes.func.isRequired,
  openJoinClassWithCodeModal: PropTypes.func.isRequired,
  archiveClassModalAction: PropTypes.func.isRequired,
  setClassToArchiveAction: PropTypes.func.isRequired,
  selectMyDashboardView: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  areCoursesLoading: PropTypes.bool.isRequired,
  areClassesLoading: PropTypes.bool.isRequired,
  courseIds: PropTypes.array,
  firstName: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  sideNavOpen: PropTypes.bool.isRequired,
  myDashboardView: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  userId: PropTypes.string,
  userRole: PropTypes.string,
  currentOrganisationId: PropTypes.string,
  orgName: PropTypes.string,
  loadCoursesData: PropTypes.func,
  organization: PropTypes.object,
  openOnboardingWizard: PropTypes.func.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func,
  openSelfRegisteredWizardAction: PropTypes.func,
  userPlatformStatistics: PropTypes.object
};
