import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Wizard } from 'react-use-wizard';
import { connect } from 'react-redux';

import {
  openJoinClassWithCodeModal,
  showSelfSelectRoleModal,
  closeSelfRegisteredWizard
} from '../../redux/actions/hubUi';

import SelfRegisteredUserOnboardingWizardWelcome from './SelfRegisteredUserOnboardingWizardWelcome/SelfRegisteredUserOnboardingWizardWelcome.js';
import SelfRegisteredUserOnboardingWizardChooseRole from './SelfRegisteredUserOnboardingWizardChooseRole/SelfRegisteredUserOnboardingWizardChooseRole.js';
import SelfRegisteredUserOnboardingWizardConfirmation from './SelfRegisteredUserOnboardingWizardConfirmation/SelfRegisteredUserOnboardingWizardConfirmation.js';
import SelfRegisteredUserOnboardingChooseRoleWizardHeader from './SelfRegisteredUserOnboardingChooseRoleWizardHeader.js';
import SelfRegisteredUserWhatToDoNextModal from './SelfRegisteredUserWhatToDoNextModal/SelfRegisteredUserWhatToDoNextModal';
import SelfRegisteredUserEnterACodeModal from './SelfRegisteredUserEnterACodeModal/SelfRegisteredUserEnterACodeModal.js';
import wizardStyles from './SelfRegisteredUserOnboardingWizard.scss';

function SelfRegisteredUserOnboardingChooseRoleWizard({
  isOpen,
  firstName,
  selectRole,
  selfSelectRoleModalOpen,
  getStaffSecondWizardTypeChoice,
  openJoinClassWithCodeModalAction,
  showSelfSelectRoleModalAction,
  closeSelfRegisteredWizardAction
}) {
  const [isOpenWhatToDoNextModal, setIsOpenWhatToDoNextModal] = useState(false);
  const [isEnterACodeModalOpen, setIsEnterACodeModalOpen] = useState(false);

  const enterACodeModalHandleNext = () => {
    setIsEnterACodeModalOpen(false);
    showSelfSelectRoleModalAction(false);
    openJoinClassWithCodeModalAction();
    closeSelfRegisteredWizardAction();
  };

  useEffect(() => {
    if (selfSelectRoleModalOpen) setIsOpenWhatToDoNextModal(selfSelectRoleModalOpen);
  }, []);

  return (
    <div className={wizardStyles.wizardContainer} data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CONTAINER">
      <Wizard header={<SelfRegisteredUserOnboardingChooseRoleWizardHeader />}>
        <SelfRegisteredUserOnboardingWizardWelcome
          isOpen={isOpen}
          firstName={firstName}
          selfSelectRoleModalOpen={selfSelectRoleModalOpen}
        />
        <SelfRegisteredUserOnboardingWizardChooseRole isOpen={isOpen} selectRole={selectRole} />
        <SelfRegisteredUserOnboardingWizardConfirmation
          isOpen={isOpen}
          setIsOpenWhatToDoNextModal={setIsOpenWhatToDoNextModal}
          setIsEnterACodeModalOpen={setIsEnterACodeModalOpen}
        />
      </Wizard>
      <SelfRegisteredUserWhatToDoNextModal
        isOpen={isOpenWhatToDoNextModal}
        onClose={() => setIsOpenWhatToDoNextModal(false)}
        onNext={selectedOption => {
          if (selectedOption === 'code') enterACodeModalHandleNext();
          else getStaffSecondWizardTypeChoice(selectedOption);
        }}
      />
      <SelfRegisteredUserEnterACodeModal
        isOpen={isEnterACodeModalOpen}
        onClose={() => setIsEnterACodeModalOpen(false)}
        selectedRole={selectRole}
        onNext={enterACodeModalHandleNext}
      />
    </div>
  );
}

SelfRegisteredUserOnboardingChooseRoleWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  selectRole: PropTypes.func.isRequired,
  openJoinClassWithCodeModalAction: PropTypes.func.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func.isRequired,
  selfSelectRoleModalOpen: PropTypes.bool.isRequired,
  getStaffSecondWizardTypeChoice: PropTypes.func.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func
};

export default connect(null, {
  openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
  showSelfSelectRoleModalAction: showSelfSelectRoleModal,
  closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
})(SelfRegisteredUserOnboardingChooseRoleWizard);
