import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import moment from 'moment';
// Styles
import styles from './HubLicenceItem.scss';
// Utils
import openProductPage from '../../../utils/openProductPage';
// Constants
import breakpoints from '../../../globals/breakpoints';
import { HubLayoutConstants, licenceStatus } from '../../../globals/hubConstants';
import { ARCHIVE_STATUS } from '../../../globals/appConstants';
// Services
import { sanitizeUrl } from '../../../utils/url';
import getLicencesStatusData from './services/getLicencesStatusData';
import getDropdownItems from '../../../components/HubDropdownMenu/Services/getDropdownItems';
import getUserLicencesStatusData from './services/getUserLicencesStatusData';
import hasExternalLink from './services/hasExternalLink';
import hasContextId from './services/hasContextId';
import getManageClassAssignmentStatus from './services/getManageClassAssignmentStatus';
// Components
import HubDropdownMenu from '../../../components/HubDropdownMenu/HubDropdownMenu';
import HubLicenceStatusItem from './HubLicenceStatusItem';
import Link from '../../../components/Link/Link';
import HubLabel from '../../../components/HubLabel/HubLabel';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import { isLtiMode } from '../../../utils/platform';

function HubLicenceItem({
  currentUsersList: currentLicencesList,
  userAssignments = {},
  classTeachersId,
  classStudentsId,
  id,
  breakpoint,
  hubContent,
  licencesContext,
  materialActions: { openRedeemModalAction = null },
  dropdownActions: { toggleDetails, toggleMaterialsToClassModal, recallLicence, removeClassMaterial },
  isTeacherAssignedToClass,
  userRole,
  dropdownItemsType,
  totalUsers,
  classroomId,
  dropdownTop,
  isUserProfilePage
}) {
  const item = get(currentLicencesList, id);
  const {
    title = '',
    series = '',
    coverImage = '',
    availableCount = 0,
    isbn = '',
    target_usertype: usertype = '',
    productLaunchUrl = '',
    platform = '',
    linkedProductDetails = {},
    archiveStatus,
    archiveDate
  } = item;

  const {
    LICENCES_CONTEXT: { CLASS, ORG, CLASSWORK, CLASSWORK_MANAGE },
    TARGET_USERTYPE,
    DOWNLOAD_TYPES
  } = HubLayoutConstants;
  const hasLinkedProducts = !!Object.keys(linkedProductDetails).length;
  const [linkedProdsDisplay, setLinkedProdsDisplay] = useState(false);
  const groupContextId = hasContextId({ userRole, isTeacherAssignedToClass, licencesContext }) ? classroomId : null;

  const itemLabel = (itemType = '') => {
    let label;

    switch (true) {
      case !hasLinkedProducts && usertype === TARGET_USERTYPE.TEACHER:
        label = hubContent.classwork_teacher_label;
        break;
      case !hasLinkedProducts && usertype === TARGET_USERTYPE.STUDENT:
        label = hubContent.classwork_student_label;
        break;
      case !itemType && hasLinkedProducts && usertype === TARGET_USERTYPE.TEACHER:
        label = hubContent.teacher_collection_label;
        break;
      case !itemType && hasLinkedProducts && usertype === TARGET_USERTYPE.STUDENT:
        label = hubContent.student_collection_label;
        break;
      case !itemType && hasLinkedProducts:
        label = hubContent.classwork_collection_label;
        break;
      default:
    }

    return label;
  };

  const { userWithoutLicence = [] } = userAssignments[id] || {};
  const isLicenceRemoved = archiveStatus === ARCHIVE_STATUS.ARCHIVED;
  const { icon = '', fill = '', text = '', date = '', status = '', hasLink = false } = licencesContext
    ? getUserLicencesStatusData(
        licencesContext === CLASS || licencesContext === ORG || licencesContext === CLASSWORK
          ? userAssignments[id]
          : userAssignments[id].productDetails,
        hubContent
      )
    : getLicencesStatusData(availableCount);

  const { manageIcon, manageFill, manageText } = getManageClassAssignmentStatus({
    usertype,
    userWithoutLicence,
    totalUsers,
    hubContent,
    isLicenceRemoved,
    classTeachersId,
    classStudentsId
  });

  const getLicenceData = (productInfo, productId) => {
    const licence =
      productInfo &&
      productInfo[productId] &&
      (productInfo[productId].licenceDetails || productInfo[productId].licenceNotStartedDetails);
    const activationCode = licence && licence.activationCode;
    const assignments = productInfo && productInfo[productId] && productInfo[productId].learningAssignments;
    const finalAssignment = assignments.filter(
      assignment =>
        assignment.activationCode === activationCode && assignment.learningAssignmentId === licence.licenceId
    )[0];
    return {
      learningAssignmentId: finalAssignment.learningAssignmentId,
      activationCode
    };
  };

  const actions = {
    toggleMaterialsToClassModal: () => toggleMaterialsToClassModal(id),
    redirectToBuyMoreLicences: `https://elt.oup.com/searchresults?q=${isbn}`,
    toggleDetails: e => {
      e.preventDefault();
      e.stopPropagation();
      toggleDetails(id, null, null);
    },
    recallLicenceAction: () => recallLicence(getLicenceData(userAssignments, id)),
    assignLicencesToClass: e => {
      e.preventDefault();
      e.stopPropagation();
      toggleMaterialsToClassModal(id);
    },
    removeMaterialFromClass: e => {
      e.preventDefault();
      e.stopPropagation();
      removeClassMaterial([id]);
    }
  };

  const launchVSTProduct = (
    e,
    platformContext = platform,
    isbnContext = isbn,
    vstDomain = get(item, 'vstDomain'),
    assignmentId = id,
    contextId = groupContextId
  ) => {
    if ((vstDomain || platformContext === 'VST') && hasLink) {
      e.stopPropagation();
      openProductPage(DOWNLOAD_TYPES.VST, isbnContext, {
        domain: vstDomain,
        assignmentId,
        contextId
      });
    }
  };

  const handleClick = e => {
    if (isLtiMode()) {
      e.preventDefault();
      e.stopPropagation();

      return false;
    }

    if (hasLinkedProducts) {
      e.preventDefault();
      e.stopPropagation();

      setLinkedProdsDisplay(!linkedProdsDisplay);
      return false;
    }

    if (!hasLink && licencesContext === CLASSWORK) {
      openRedeemModalAction();
    }

    if (licencesContext === CLASSWORK_MANAGE) {
      toggleMaterialsToClassModal(id);
    }

    if (!isUserProfilePage) launchVSTProduct(e);
    return true;
  };

  const handleLinkedProductClick = (e, productDetails, linkedProductId) => {
    e.stopPropagation();

    if (licencesContext === CLASSWORK && (status === licenceStatus.EXPIRED || status === licenceStatus.NO_LICENCE)) {
      openRedeemModalAction();
    }

    if (licencesContext === CLASSWORK_MANAGE) {
      toggleMaterialsToClassModal(linkedProductId);
    }
    if (!isUserProfilePage)
      launchVSTProduct(e, productDetails.platform, productDetails.isbn, productDetails?.vstDomain, linkedProductId);
    return true;
  };

  const statusDetails = {
    icon,
    fill,
    text,
    date,
    availableCount,
    isLicenceRemoved,
    status,
    archiveDate: moment(archiveDate).format('L'),
    manageClassData: {
      manageIcon,
      manageFill,
      manageText
    }
  };

  const dropdownOptions = {
    status,
    isTeacherAssignedToClass
  };

  const hasCoverImage = product => product.coverImage || '';

  const showLink = hasExternalLink({ userRole, hasLink, isTeacherAssignedToClass, licencesContext });
  const removedStyle = isLicenceRemoved ? styles.imgRemovedLicence : '';

  return (
    <div className={!linkedProdsDisplay ? `${styles.itemWrapper} ${styles.collapsed}` : styles.itemWrapper}>
      <Link
        to={showLink && !hasLinkedProducts && !isUserProfilePage ? productLaunchUrl : null}
        onClick={!showLink || hasLinkedProducts || platform === 'VST' ? handleClick : null}
      >
        <li key={id} className={styles.licenceContainer}>
          <div className={styles.leftContainer}>
            <div className={styles.leftContainerContent}>
              <div
                className={
                  hasLinkedProducts
                    ? `${styles.imgContainer} ${styles.hasLinkedProducts} ${removedStyle}`
                    : `${styles.imgContainer} ${removedStyle}`
                }
              >
                {coverImage ? (
                  <img src={sanitizeUrl(coverImage)} alt={`${title} ${series}`} />
                ) : (
                  <div className={styles.fallbackSvgContainer}>
                    <SVGIcon glyph={GLYPHS.OUP_SYMBOL_REBRANDING} />
                  </div>
                )}
              </div>
              <div
                className={`${styles.licenceInfoContainer} ${
                  showLink && !hasLinkedProducts && !isUserProfilePage ? styles.licenceInfoContainerLink : ''
                }`}
              >
                <div className={styles.licenceTitle}>
                  <span>
                    {title}
                    {hasLink && !hasLinkedProducts && !isUserProfilePage && <SVGIcon glyph={GLYPHS.ICON_LINK} />}
                  </span>
                </div>
                {itemLabel() && (
                  <HubLabel
                    text={itemLabel()}
                    isCollapsed={!linkedProdsDisplay}
                    PrefixIcon={hasLinkedProducts && <SVGIcon glyph={GLYPHS.CHEVRON_DOWN} />}
                  />
                )}
                {hasLinkedProducts && (
                  <span className={styles.linkedProductsIcon}>
                    <SVGIcon glyph={GLYPHS.ICON_COURSE} />
                    {Object.keys(linkedProductDetails).length}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.middleContainer}>
            <HubLicenceStatusItem
              licencesContext={licencesContext}
              statusDetails={statusDetails}
              styles={styles}
              hubContent={hubContent}
              breakpoint={breakpoint}
            />
            {breakpoint === breakpoints.XXS && !isLicenceRemoved && (
              <div className={styles.dropdownContainer}>
                <HubDropdownMenu
                  dropdownData={getDropdownItems(dropdownItemsType, hubContent, dropdownOptions, actions, userRole)}
                  parentId={id}
                  customClassname={styles.dropDownMenu}
                  dropdownTop={dropdownTop}
                />
              </div>
            )}
          </div>
          <div className={styles.rightContainer}>
            {breakpoint !== breakpoints.XXS && !isLicenceRemoved && (
              <HubDropdownMenu
                dropdownData={getDropdownItems(dropdownItemsType, hubContent, dropdownOptions, actions, userRole)}
                parentId={id}
                customClassname={styles.dropDownMenu}
                dropdownTop={dropdownTop}
              />
            )}
          </div>
        </li>
      </Link>
      {hasLinkedProducts && (
        <div className={styles.linkedProductsContainer}>
          <div className={styles.innerLinkedContainer}>
            {Object.keys(linkedProductDetails).map(linkedProductId => (
              <Link
                key={linkedProductId}
                to={showLink && !isUserProfilePage ? linkedProductDetails[linkedProductId].productLaunchUrl : null}
                onClick={e => handleLinkedProductClick(e, linkedProductDetails[linkedProductId], linkedProductId)}
              >
                <div className={styles.linkedProductItem}>
                  <div className={styles.leftContainer}>
                    <div className={styles.leftContainerContent}>
                      <div className={`${styles.imgContainer} ${removedStyle}`}>
                        {hasCoverImage(linkedProductDetails[linkedProductId]) ? (
                          <img
                            src={sanitizeUrl(linkedProductDetails[linkedProductId].coverImage)}
                            alt={`${linkedProductDetails[linkedProductId].title}`}
                          />
                        ) : (
                          <div className={styles.fallbackSvgContainer}>
                            <SVGIcon glyph={GLYPHS.OUP_SYMBOL_REBRANDING} />
                          </div>
                        )}
                      </div>
                      <div
                        className={`${styles.licenceInfoContainer} ${
                          showLink && !isUserProfilePage ? styles.licenceInfoContainerLink : ''
                        }`}
                      >
                        <div className={styles.licenceTitle}>
                          <span>
                            {linkedProductDetails[linkedProductId].title}
                            {hasLink && !isUserProfilePage && <SVGIcon glyph={GLYPHS.ICON_LINK} />}
                          </span>
                        </div>

                        {itemLabel('collectionItem') && (
                          <HubLabel
                            text={itemLabel('collectionItem')}
                            isCollapsed={!linkedProdsDisplay}
                            PrefixIcon={hasLinkedProducts && <SVGIcon glyph={GLYPHS.CHEVRON_DOWN} />}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

HubLicenceItem.defaultProps = {
  licencesContext: '',
  userAssignments: {}
};

HubLicenceItem.propTypes = {
  currentUsersList: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  classStudentsId: PropTypes.array,
  classTeachersId: PropTypes.array,
  hubContent: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  dropdownActions: PropTypes.object.isRequired,
  dropdownTop: PropTypes.bool,
  materialActions: PropTypes.object.isRequired,
  licencesContext: PropTypes.string,
  userAssignments: PropTypes.object,
  isTeacherAssignedToClass: PropTypes.bool.isRequired,
  userRole: PropTypes.string,
  dropdownItemsType: PropTypes.string.isRequired,
  totalUsers: PropTypes.number.isRequired,
  classroomId: PropTypes.string,
  isUserProfilePage: PropTypes.bool
};

export default HubLicenceItem;
